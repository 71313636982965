import { apiUserLogin, apiUserLogout } from "../services/tpsApi/tpsApiUser";
import LocalStorageUtils from "../utils/LocalStorageUtils";
import Vuex from 'vuex';

const AUTH_TOKEN_KEY = "tpsAuthToken";
const USER = "tpsUser";
const EXTRA_PERMISSION = "tpsExtraPermission";
const PERMISSION = "tpsPermission";
const IDLE = "tpsIdle";
const BRAND_ID = "tpsBrandID";
const WRITE = "tpsWrite";

const store = new Vuex.Store({
    state: () => ({
      _currentUser: LocalStorageUtils.getItem(USER),
      _authToken: LocalStorageUtils.getItem(AUTH_TOKEN_KEY),
      _extraPermission: LocalStorageUtils.getItem(EXTRA_PERMISSION),
      _permission: LocalStorageUtils.getItem(PERMISSION),
      _idle: LocalStorageUtils.getItem(IDLE),
      _brand_id: LocalStorageUtils.getItem(BRAND_ID),
      _write: LocalStorageUtils.getItem(WRITE)
    }),
    getters: {
      currentUser(state) {
        return state._currentUser;
      },
      authToken(state) {
        return state._authToken || "";
      },
      isLoggedIn(state) {
        return !!state._currentUser;
      },
      extraPermission(state) {
        return state._extraPermission;
      },
      permission(state) {
        return state._permission;
      },
      idle(state) {
        return state._idle;
      },
      brand_id(state) {
        return state._brand_id;
      },
      write(state) {
        return state._write;
      }
    },
    mutations: {
      SET_CURRENT_USER(state, currentUser) {
        if (currentUser) {
          state._currentUser = currentUser;
          LocalStorageUtils.setItem(USER, currentUser);
        } 
        else {
          state._currentUser = undefined;
          LocalStorageUtils.removeItem(USER);
        }
      },
      SET_AUTH_TOKEN(state, authToken) {
        if (authToken) {
          let token = "Bearer " + authToken;
          state._authToken = token;
          LocalStorageUtils.setItem(AUTH_TOKEN_KEY, token);
        } else {
          state._authToken = undefined;
          LocalStorageUtils.removeItem(AUTH_TOKEN_KEY);
        }
      },
      SET_EXTRA_PERMISSIOM(state, extra_permission) {
        state._extraPermission = extra_permission;
        LocalStorageUtils.setItem(EXTRA_PERMISSION, extra_permission);
      },
      SET_PERMISSION(state, permission) {
        state._permission = permission;
        LocalStorageUtils.setItem(PERMISSION, permission);
      },
      SET_IDLE(state, idle) {
        state._idle = idle;
        LocalStorageUtils.setItem(IDLE, idle);
      },
      SET_BRAND_ID(state, brand_id) {
        state._brand_id = brand_id;
        LocalStorageUtils.setItem(BRAND_ID, brand_id);
      },
      SET_WRITE(state, write) {
        state._write = write;
        LocalStorageUtils.setItem(WRITE, write);
      }
    },
    actions: {
      async login({ commit } ,params) {
        const res = await apiUserLogin(params);
        if(res.msg) return res;

        if(res.permission) {
          console.log("vux permission:", res.permission);
          commit('SET_PERMISSION', res.permission);
        }
        if(res.extra_permission) {
          console.log("vuex extra_permission:", JSON.stringify(JSON.parse(res.extra_permission).enable).slice(1, -1));
          commit('SET_EXTRA_PERMISSIOM', JSON.stringify(JSON.parse(res.extra_permission).enable).slice(1, -1));
        }
        if(res.idle) {
          commit('SET_IDLE', res.idle);
        }
        if(res.token) {
          commit('SET_CURRENT_USER', params.e_mail);
          commit('SET_AUTH_TOKEN', res.token);
        }
        if(res.write !== null) {
          commit('SET_WRITE', res.write);
        }
        //Set brand_id
        console.log("vuex brand_id:", res.brand_id);
        commit('SET_BRAND_ID', res.brand_id);
      },
      async logout({commit}) {
        try{
          const res = await apiUserLogout();
        }
        catch(err){
          console.log("api logout failed");
        }

        commit("SET_AUTH_TOKEN", undefined);
        commit("SET_CURRENT_USER", undefined);
        commit("SET_BRAND_ID", undefined);
        commit("SET_EXTRA_PERMISSIOM", undefined);
        commit("SET_PERMISSION", undefined);
        commit("SET_IDLE", undefined);
        commit("SET_WRITE", undefined);
      }
    }
  });

  export default store;