import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import Store from "../../store";
import router from "@/router";

const instance: Readonly<AxiosInstance> = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 60000
  });

const AuthInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
    //console.log(config);
    const accessToken = Store.getters.authToken;
    if (!accessToken && !config.url?.match('/user/log')) {
      alert("認証過期，請重新登入系統");
      router.push({ path: "/login" });
      throw new axios.Cancel('Token mysteriously lost, return to login page.');
    }
    config.headers.Authorization = accessToken;
    return config;
};

const OnResponseSuccess = (response: AxiosResponse<any>): AxiosResponse<any> =>
  response;

const OnResponseFailure = (error: any): Promise<any> => {  
  if (!error.response) {
    //console.log("錯誤： 沒有網際網路連線");
  } 
  else {
    if(error?.response?.status == 401) {
      alert("認証過期，請重新登入系統");
      router.push({ path: "/login" });
    }
    else if(error?.response?.status == 402) {
      //console.log("錯誤：帳號或密碼錯誤")
      if (error?.response?.data?.status !== undefined) {
        return Promise.reject(error.response.data);
      }
      // Lagacy response payload
      return Promise.reject(new Error(error?.response?.data?.msg));
    }
    else if(typeof error?.response?.data?.msg == "object") {
      alert("錯誤：" + error?.response?.data?.msg?.sqlMessage);
    }
    else if(error?.response?.data?.alert) {
      return Promise.reject(error.response.data);
    }
    else {
      alert("錯誤：" + error?.response?.data?.msg);
    }
  }
  
  return Promise.reject(error?.response);
};

instance.defaults.headers.get.Accepts = "application/json";
instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

instance.interceptors.request.use(AuthInterceptor, function (error) {
  return Promise.reject(error);
});
instance.interceptors.response.use(OnResponseSuccess, OnResponseFailure);

export default instance;