import '@babel/polyfill';
import 'mutationobserver-shim';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import  store from './store';
import './scss/main.scss';

let app = createApp(App).use(store).use(router);

app.config.errorHandler = function (err, vm, info) {
    console.log("err:",err);
    console.log("info:",info);
}


app.mount("#app");
  