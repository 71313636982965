
import { Options, Vue } from 'vue-class-component';
import '../scss/md-login.scss';

@Options({
    components: {
    },
})

export default class Login extends Vue {
    loginErr: boolean = false;
    account: string = "";
    password: string = "";
    remember: boolean = false;

    mounted() {
        console.log(this.$store.getters.authToken)
        if (this.$store.getters.authToken) {
            this.$store.dispatch("logout");
        }
      
        
        //頁面初始化時，如果帳號密碼cookie存在則填充
        if (this.getCookie('user') && this.getCookie('pswd')) {
            this.account = this.getCookie('user');
            this.password = this.getCookie('pswd');
            this.remember = true;
        }
    }

    async login(): Promise<void> {
        if (this.remember) { 
            this.setCookie('user',this.account,90); //儲存帳號到cookie，有效期7天
            this.setCookie('pswd',this.password,90); //儲存密碼到cookie，有效期7天
        }
        const res: any = await this.$store.dispatch("login", { "e_mail": this.account, "password": this.password });
        if (res && res.status === "alert") {
            this.loginErr = true;    
        } else if (this.$store.getters.authToken) {
            let extra_permission = this.$store.getters.extraPermission.split(",");
            console.log("extra_permission:",extra_permission);
            if(extra_permission) {
                switch(extra_permission[0]) {
                    case '1':
                        this.$router.push("/chiayi");
                        break;
                    case '2':
                        this.$router.push("/brand");
                        break;
                    case '3':
                        this.$router.push("/company");
                        break;
                    case '4':
                        this.$router.push("/reader");
                        break;
                    case '5':
                        this.$router.push("/driver");
                        break;
                    case '6':
                        this.$router.push("/report");
                        break;
                    case '7':
                        this.$router.push("/report-search");
                        break;
                    case '8':
                        this.$router.push("/report-chiayi");
                        break;
                    case '9':
                        this.$router.push("/corporation");
                        break;
                    case '10':
                        this.$router.push("/user");
                        break;
                    case '11':
                        this.$router.push("/user-transaction");
                        break;
                    case '12':
                        this.$router.push("/report-abnormal-deduct");
                        break;
                    case '13':
                        this.$router.push("/report-taichung");
                        break;
                    case '14':
                        this.$router.push("/report-daily-transaction-sum");
                        break;
                    case '15':
                        this.$router.push("/announcement");
                        break;
                    case '16':
                        this.$router.push("/payment-rule");
                        break;
                    case '17':
                        this.$router.push("/operation-rule");
                        break;
                     case '18':
                        this.$router.push("/report-route");
                        break;
                    default:
                      alert("無閱覽權限");
                }

            }
        }
    }
    checkChange(){
        // console.log('123')
        if (!this.remember) {
            this.delCookie('user');
            this.delCookie('pswd');
        }
    }
    //設定cookie
    setCookie (name,value,day) {
        var date = new Date();
        date.setDate(date.getDate()+day);
        document.cookie = name+'='+value+';expires='+date;
    }
    //獲取cookie
    getCookie(name){
        var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        if (match) {
            return(match[2]);
        } else {
            return ""
        }
    }
    //刪除cookie
    delCookie(name){
        this.setCookie(name,null,-1);
    }
}
