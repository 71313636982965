import { createRouter, createWebHistory } from 'vue-router';
import { apiUserGetListTotalNum } from '../services/tpsApi/tpsApiUser';
import Login from '../views/Login.vue';
import Store from "../store";

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/announcement',
    name: 'Announcement',
    component: () => import('../views/Announcement.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/chiayi',
    name: 'Chiayi',
    component: () => import('../views/Chiayi.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/brand',
    name: 'Brand',
    component: () => import('../views/Brand.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/company',
    name: 'Company',
    component: () => import('../views/Company.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reader',
    name: 'Reader',
    component: () => import('../views/Reader.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/driver',
    name: 'Driver',
    component: () => import('../views/Driver.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/report',
    name: 'Report',
    component: () => import('../views/Report.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/report-search',
    name: 'ReportSearch',
    component: () => import('../views/ReportSearch.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/report-chiayi',
    name: 'ReportChiayi',
    component: () => import('../views/ReportChiayi.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/report-abnormal-deduct',
    name: 'ReportAbnormalDeduct',
    component: () => import('../views/ReportAbnormalDeduct.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/report-taichung',
    name: 'ReportTaichung',
    component: () => import('../views/ReportTaichung.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/report-daily-transaction-sum',
    name: 'ReportDailyTransactionSum',
    component: () => import('../views/ReportDailyTransactionSum.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/report-route',
    name: 'ReportRoute',
    component: () => import('../views/ReportRoute.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/corporation',
    name: 'Corporation',
    component: () => import('../views/Corporation.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('../views/User.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/user-transaction',
    name: 'Transaction',
    component: () => import('../views/Transaction.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/payment-rule',
    name: 'PaymentRule',
    component: () => import('../views/PaymentRule.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/operation-rule',
    name: 'OperationRule',
    component: () => import('../views/OperationRule.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reader-software',
    name: 'ReaderSoftware',
    component: () => import('../views/ReaderSoftware.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/reader-version',
    name: 'ReaderVersion',
    component: () => import('../views/ReaderVersion.vue'),
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "active",
  routes
})

router.beforeEach(async(to, from, next) => {
  if(!navigator.onLine) {
    alert("錯誤： 沒有網際網路連線");
    next(false);
  }
  else if(to.matched.some(record => record.meta.requiresAuth)) {
    if(Store.getters.isLoggedIn) {
      //Test if token is not expired
      await apiUserGetListTotalNum();
      next()
    } else {
      next({
        path: '/login',
      })
      alert("請登入系統");
    }
  } else {
    next()
  }
})

export default router
