import tpsApiInstance from "./tpsApiBase";
import {
    IUserList,
    IUserDetail,
    IUserLoginRequestParams,
    IUserGetListTotalNumRequestParams,
    IUserGetListRequestParams,
    IUserGetDetailRequestParams,
    IUserDetailUpdateRequestParams,
    IUserCreateResponseSchema,
    IUserDetailUpdateResponseSchema
} from './models/user';

const USER_PATH = "/user";

export const apiUserLogin = async (
    pData: IUserLoginRequestParams): Promise<any> => {
    let res: any = null;
    try { 
        res = await tpsApiInstance.post(`${USER_PATH}/login`, pData);
    } catch(error) {
        return error;
    }
    return res?.data;
};

export const apiUserLogout = async(): Promise<void> => {
    const res = await tpsApiInstance.get(`${USER_PATH}/logout`);
    console.log("logout response:",res)
    return res?.data;
};

export const apiUserGetListTotalNum = async(
    pData?: IUserGetListTotalNumRequestParams): Promise<number> => {
    let query: string = "?count=total";
    if(pData && pData.filter) query+= `&filter=${pData.filter}`;
    if(pData && pData.filter_company) query+= `&filter_company=${pData.filter_company}`;
    if(pData && pData.filter_brand) query+= `&filter_brand=${pData.filter_brand}`;
    console.log("query:",query);
    const res = await tpsApiInstance.get(`${USER_PATH}${query}`);
    console.log("total:",res?.data[0]?.total);
    return res?.data[0]?.total;
};

export const apiUserGetList = async(
    pData?: IUserGetListRequestParams): Promise<IUserList | Blob> => {
    let query: string = "?";
    if(pData && pData.limit && pData.page) query += `limit=${pData.limit}&offset=${(pData.page-1)*pData.limit}`;  
    if(pData && pData.filter) query+= `&filter=${pData.filter}`;
    if(pData && pData.filter_company) query+= `&filter_company=${pData.filter_company}`;
    if(pData && pData.filter_brand) query+= `&filter_brand=${pData.filter_brand}`;

    if(pData && pData.output) {
        query += `&output=${pData.output}`;
        console.log("query:",query);
        const res = await tpsApiInstance.get(`${USER_PATH}${query}`,{responseType: 'blob'});
        return res?.data as Blob;
    }
    else {
        console.log("query:",query);
        const res = await tpsApiInstance.get(`${USER_PATH}${query}`);
        return res?.data as IUserList;
    }
};

export const apiUserGetDetail = async (
    pData: IUserGetDetailRequestParams): Promise<IUserDetail> => {
        console.log(pData,`${USER_PATH}/${pData.id}`)
    const res = await tpsApiInstance.get(`${USER_PATH}/${pData.id}`);
    console.log(res.data[0]);
    return res?.data[0] as IUserDetail;
};

export const apiUserCreate = async (
    pData: IUserDetail): Promise<IUserCreateResponseSchema> => {
    console.log(JSON.stringify(pData));
    const res = await tpsApiInstance.post(USER_PATH, pData);
    return res?.data as IUserCreateResponseSchema;
};

export const apiUserDetailUpdate = async (
    pData: IUserDetailUpdateRequestParams): Promise<IUserDetailUpdateResponseSchema> => {
    console.log(JSON.stringify(pData.data));
    const res = await tpsApiInstance.post(`${USER_PATH}/${pData.id}`, pData.data);
    return res?.data as IUserDetailUpdateResponseSchema;
};

