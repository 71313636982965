
import { Options, Vue } from 'vue-class-component';
import Navbar from '@/components/Navbar.vue';

@Options({
  components: {
    Navbar
  }
})

export default class App extends Vue {
  created() {
    var timeout;
    var app = this;
    function refresh() {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if(app.$route.path!="/login"){
          app.$router.push("/login");
          alert("閒置時間過長，系統已自動登出");
        }
      }, app.$store.getters.idle * 1000)
    }
    
    refresh();
    document.addEventListener('click', refresh);
    document.addEventListener('mousemove', refresh);
  }
}
