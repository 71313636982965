
import { Options, Vue } from 'vue-class-component';
import { Prop } from "vue-property-decorator";
import { PAGENO } from '@/services/common/IPageNo.js';
import '../scss/md-navbar.scss';
import { stringifyQuery } from 'vue-router';

@Options({
  components: {
    
  },
})


export default class Navbar extends Vue {
    @Prop({ required: true }) account: string = "";
    showToggle: boolean = false;
    showDropdown: string = "";
    extra_permission: Array<string> = [];
    pageNo: {} = PAGENO;

    mounted() {
        if(this.$store.getters.extraPermission) {
            console.log("extraPermission:",this.$store.getters.extraPermission)
            this.extra_permission = this.$store.getters.extraPermission.split(",");
        }
    }

    getPageVisible(pPageName: string[]) {
        const extraPermission = [...this.extra_permission];
        let visible: boolean = false;
        if(extraPermission && (typeof pPageName === 'object')) {
            Object.values(pPageName).forEach(element => {
                //if(pPageName == ['付款參數管理','營運參數管理','公告列表']){
                //}
                if(extraPermission.includes(this.pageNo[element])) {
                    visible = true;
                }
            })
        } 
        return visible;
    }

    doToggle() {
        this.showToggle = !this.showToggle;
    }

    doDropdown(pShow: boolean, pPath: string) {
        if(pShow && this.showDropdown != pPath) {
            this.showDropdown = pPath;
            if(!this.$route.path.includes(pPath)) {
                this.$router.push(pPath);
            }
        }
        else {
            this.showDropdown = "";
        }   
    }

    async logout(): Promise<void> {
        this.$router.push("/");
    }
}
