export const PAGENO = {
    "嘉義市低收入戶": "1",
    "品牌資訊": "2",
    "公司資訊": "3",
    "卡機資料": "4",
    "駕駛資料": "5",
    "交易明細表": "6",
    "卡機版本查詢報表": "7",
    "嘉義復康巴士請款明細表": "8",  
    "票證公司": "9",
    "帳號設定": "10",
    "異常交易通知": "11", 
    "異常扣除明細表": "12",
    "愛心卡乘車統計表": "13", 
    "愛心卡日交易總表": "14",
    "公告列表": "15",
    "付款參數管理": "16",
    "營運參數管理": "17",
    "行駛軌跡明細表": "18",
    "卡機軟體管理": "19",
    "批次卡機佈版": "20"
}